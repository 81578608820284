module.exports = {
  defaultTitle: 'natterstefan',
  logo: 'https://natterstefan.me/favicon/favicon-512.png',
  author: 'Stefan Natter',
  url: 'https://natterstefan.me',
  legalName: 'Stefan Natter',
  defaultDescription: 'I’m Stefan Natter, Software Engineer from Austria!',
  socialLinks: {
    twitter: 'http://www.twitter.com/natterstefan',
    github: 'https://github.com/natterstefan',
    linkedin: 'https://linkedin.com/in/natterstefan',
  },
  googleAnalyticsID: 'UA-11334432-20',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  social: {
    facebook: '', // TODO: add
    twitter: '@natterstefan',
  },
  address: {
    country: 'Austria',
  },
  contact: {
    email: 'stefan@natter',
    phone: '',
  },
  recaptcha_key: '6LcAp4YUAAAAAAQazihyASkpBfp2oxQ2Rwr9wLkV',
}
