import React from 'react'
import { Link } from 'gatsby'
import AnchorLink from 'react-anchor-link-smooth-scroll'
// https://nesin.io/blog/get-current-path-in-gatsby
import { globalHistory } from '@reach/router'

import { Wrapper } from './styles'

const NavbarLinks = ({ desktop }) => {
  const path = globalHistory.location.pathname

  return (
    <Wrapper desktop={desktop}>
      {path === '/imprint' ? (
        <>
          <Link to="/#about">About</Link>
          <Link to="/#projects">Projects</Link>
          <Link to="/#contact">Contact</Link>
        </>
      ) : (
        <>
          <AnchorLink href="#about">About</AnchorLink>
          <AnchorLink href="#projects">Projects</AnchorLink>
          <AnchorLink href="#contact">Contact</AnchorLink>
        </>
      )}

      <Link to="/imprint">Imprint</Link>
    </Wrapper>
  )
}

export default NavbarLinks
